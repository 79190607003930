import Cookies from "js-cookie";
import { useAppAuthorisationList } from "@gocardless/api/dashboard/app-authorisation";
import { useGetPrimaryCreditorResponse } from "src/queries/creditor";
import { CookieStorage } from "src/common/constants/storage";
import { useI18n } from "src/technical-integrations/i18n";
import { useActivationDiscount } from "src/components/routes/Setup/common/components/ActivationDiscount/useActivationDiscount";
import { EpochTimestamp } from "src/components/date";
import { useOrganisationSelf } from "@gocardless/api/dashboard/organisation";
import { useAccessToken } from "src/common/authorisation";
import { useRewardsList } from "src/components/rewards-modal/useRewardsList";
import { DiscountType } from "@gocardless/api/dashboard/types";

export interface OptimizelyAttributes {
  organisation_id: string;
  account_type: string;
  connected_app_id: string;
  signup_app_id: string;
  discount_type: string;
  organisation_created_at: number | string;
  geo: string;
  package_state: string;
  creditor_type: string;
  should_pay_for_intelligent_retries: boolean;
  verification_status: string;
  is_eligible_for_share_of_wallet_experiments: boolean;
  locale: string;
  cypress: string;
  activated: boolean;
  screen_width: number;
  fpj_enabled: boolean;
  referral_scheme_enabled: boolean;
  is_payment_provider: boolean;
  account_age_in_days: number;
  is_multi_creditor: boolean;
  sdd_enabled: boolean;
  is_eligible_for_self_serve_account_closure: boolean;
  cpv_enabled: boolean;
  is_eligible_for_pricing: boolean;
  sub_segment: string;
  sub_segment_variant: string;
  has_rewards_create_account_discount: boolean;
  anonymous_client_id: string;
  ga_client_id: string;
  country: string;
  pre_bucketed_in_referral_rewards_v1?: boolean;
}

export function useOptimizelyAttributes():
  | OptimizelyAttributes
  | null
  | undefined {
  const [locale] = useI18n();

  const { data: creditorResponse, isLoading: creditorLoading } =
    useGetPrimaryCreditorResponse();
  const creditor = creditorResponse?.creditors;
  const [accessToken] = useAccessToken();

  const { data, isLoading: organisationLoading } = useOrganisationSelf(
    accessToken?.links?.organisation || null
  );
  const organisation = data?.organisations;
  const { data: appAuthorisations, isLoading: appAuthorisationsLoading } =
    useAppAuthorisationList();
  const { activationDiscount, remainingDays } = useActivationDiscount();
  const { isUnlocked } = useRewardsList();

  const cypressFeatureFlag = JSON.parse(
    window.localStorage.getItem("gc.cypress") || "{}"
  );

  const is_eligible_for_pricing = Cookies.get(
    CookieStorage.PricingOptimizelyID || ""
  );

  const organisationCreatedAtTimestamp = EpochTimestamp(
    organisation?.created_at
  );

  const accountAgeInDays = Math.floor(
    (new Date().getTime() -
      new Date(organisation?.created_at as unknown as string).getTime()) /
      (1000 * 3600 * 24)
  );

  // distinguish an undefined value from a null value. Undefined is for when we are still loading the data
  if (organisationLoading || appAuthorisationsLoading || creditorLoading) {
    return undefined;
  }

  // Loading is done, but there is no data, set the attribute to null
  if (!creditor && !organisation && !appAuthorisations) {
    return null;
  }

  const attributes: OptimizelyAttributes = {
    organisation_id: organisation?.id ?? "",
    account_type: organisation?.account_type ?? "",
    connected_app_id:
      appAuthorisations?.app_authorisations?.[0]?.links?.app ?? "",
    signup_app_id: organisation?.links?.signup_app ?? "",
    discount_type: remainingDays ? activationDiscount?.discount_type ?? "" : "",
    organisation_created_at: organisationCreatedAtTimestamp,
    geo: creditor?.geo ?? "",
    package_state: organisation?.package_state ?? "",
    creditor_type: creditor?.creditor_type ?? "",
    should_pay_for_intelligent_retries:
      organisation?.should_pay_for_intelligent_retries ?? false,
    verification_status: creditor?.verification_status ?? "",
    is_eligible_for_share_of_wallet_experiments:
      organisation?.is_eligible_for_share_of_wallet_experiments ?? true,
    locale: locale,
    cypress: cypressFeatureFlag,
    activated: creditor?.activated ?? true,
    screen_width: screen.width,
    fpj_enabled: organisation?.fpj_enabled ?? false,
    referral_scheme_enabled: organisation?.referral_scheme_enabled ?? false,
    is_payment_provider: !!organisation?.links?.payment_provider,
    account_age_in_days: accountAgeInDays,
    is_multi_creditor: organisation?.is_multi_creditor ?? false,
    sdd_enabled: creditor?.sdd_enabled ?? false,
    is_eligible_for_self_serve_account_closure:
      organisation?.is_eligible_for_self_serve_account_closure ?? false,
    cpv_enabled: organisation?.cpv_enabled ?? false,
    is_eligible_for_pricing: Boolean(is_eligible_for_pricing) ?? false,
    sub_segment: organisation?.sub_segment ?? "",
    sub_segment_variant: organisation?.sub_segment_variant ?? "",
    has_rewards_create_account_discount: isUnlocked(
      DiscountType.The30DaysFreeFeeActivationCreateAccount
    ),
    anonymous_client_id: "",
    ga_client_id: "",
    country: "",
    pre_bucketed_in_referral_rewards_v1:
      organisation?.pre_bucketed_in_referral_rewards_v1 ?? false,
  };

  return attributes;
}
